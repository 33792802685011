import * as React from "react";
import Button from "../Button";
import Text from "../Text";
import { useForm } from "react-hook-form";
import { useGetInTouchLocation } from "hooks/Forms/useGetInTouchLocation";
import ThankYouPopup from "components/popup/thankYouPopup";
import { useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import formCommonPostData from "utils/form-common-post-data";
import Loader from "components/loader";
import RedExclamation from "assets/redexclon.svg";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";
import TrustpilotReviews from "@pittica/gatsby-plugin-trustpilot-widget";

const EnquiryForm = ({ title, subtitle, highlightWords, highlightedColor }) => {
  // const [checkBox, setCheckBox] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const { status, mutateAsync } = useGetInTouchLocation();

  const onSubmit = (data) => {
    const postData1 = {
      name: data.name,
      email: data.email,
      message: data.message,
      consent: data.consent,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      setOpen(true);
      reset();
    });
  };
  return (
    <div className={`w-80 md:w-96 bg-white py-8 px-8 rounded-lg shadow-card2`}>
      <div className="flex flex-col">
        <Text
          highlightedText={highlightWords}
          highlightedColor={highlightedColor}
          className="text-darkBlue text-24 leading-[34px] font-secondary font-medium pb-2"
          as="div"
        >
          {title}
        </Text>
        <Text
          as="div"
          className="text-[13px] leading-[22px] font-normal font-opensans text-darkBlue"
        >
          {subtitle}
        </Text>
        <div className="antialiased">
          <div className="max-w-xl mx-auto divide-y md:max-w-4xl">
            <div className="mt-4 max-w-md">
              <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                <div className="grid grid-cols-1 gap-2 text-sm text-black">
                  <label className="block relative">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name*"
                      // className="mt-1 block w-full border border-[#A1A1AA] rounded-md focus:border-2  focus:border-pop-up-100 text-sm font-opensans py-2 px-3"
                      className={`mt-1 block w-full border border-[#A1A1AA] rounded-md  text-sm font-opensans py-2 px-3 ${
                        errors.name
                          ? "border-red outline-none focus:border-[#ff0000]"
                          : ""
                      }`}
                      {...register("name", {
                        required: true,
                      })}
                    />
                    {errors.name && (
                      <img
                        src={RedExclamation}
                        alt="Error"
                        className="absolute right-2 top-[56%] transform -translate-y-1/2 w-4 h-4"
                      />
                    )}
                  </label>
                  {errors.name && (
                    <span className="text-red text-sm font-opensans">
                      {errorMessage}
                    </span>
                  )}
                  <label className="block relative">
                    <input
                      type="email"
                      name="email"
                      // placeholder={`Email Address ${(
                      //   <i className="text-red">*</i>
                      // )}`}
                      placeholder="Email Address*"
                      className={`mt-1 block w-full border border-[#A1A1AA] rounded-md  text-sm  font-opensans py-2 px-3 ${
                        errors.email
                          ? "border-red outline-none focus:border-[#ff0000]"
                          : ""
                      }`}
                      // className="mt-1 block w-full border-light-silver border text-sm font-opensans py-2 px-3"
                      {...register("email", {
                        required: true,
                        pattern: patternEmail,
                      })}
                    />
                    {errors.email && (
                      <img
                        src={RedExclamation}
                        alt="Error"
                        className="absolute right-2 top-[56%] transform -translate-y-1/2 w-4 h-4"
                      />
                    )}
                  </label>
                  {errors.email && (
                    <span className="text-red text-sm font-opensans">
                      {errorMessageEmail}
                    </span>
                  )}
                  <label className="block">
                    <textarea
                      placeholder="Tell us more about your needs"
                      className={`mt-1 block w-full py-2 px-3 border border-[#A1A1AA] rounded-md focus:border-2  focus:border-pop-up-100 text-sm font-opensans ${
                        errors.message ? "border-red outline-none" : ""
                      }`}
                      rows="3"
                      name="message"
                      {...register("message")}
                    ></textarea>
                  </label>
                  {/* {errors.message && (
                    <span className="text-red text-sm font-opensans">
                      This field is required
                    </span>
                  )} */}
                  <div className="mb-5">
                    <div className="flex items-baseline gap-2">
                      <p className="text-sm text-black font-opensans">
                        I agree to Tomedes'{" "}
                        <Link
                          to="/privacy"
                          className="text-orange hover:text-newOrange-100 underline transition-colors"
                        >
                          Privacy Policy
                        </Link>{" "}
                        by submitting this form.
                      </p>
                    </div>
                  </div>
                  <input type="hidden" id="zc_gad" name="zc_gad" value="" />
                  <Button
                    title={status === "loading" ? <Loader /> : "SUBMIT"}
                    className="w-full text-sm text-center text-white font-opensans font-semibold mt-1"
                    variant="rounded"
                  />
                </div>
              </form>

              <TrustpilotReviews
                language="en"
                culture="US"
                theme="light"
                width="100%"
                height="20px"
                template="5419b637fa0340045cd0c936"
                business="5b18d84cd3737d0001df3bec"
                username="www.tomedes.com"
              />
            </div>
          </div>
        </div>
      </div>
      <ThankYouPopup open={open} setOpen={setOpen} />
    </div>
  );
};
export default EnquiryForm;
